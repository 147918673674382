.swiper {
  width: 100%;
  height: 100%;
  padding: 45px 0;

  @media screen and (max-width: 991px) {
    max-width: 730px;
    width: 100%;
    margin: 0 auto;
  }

  .swiperSlide {
    padding: 0 8px;

    @media screen and (max-width: 991px) {
      article {
        flex-direction: column;
      }
    }

    article > div {
      height: 225px;
    }
  }
}