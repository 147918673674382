.categoriesSection {
  h3 {
    font-size: 2.4rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    @media(max-width: 767px) {
      text-align: center;
    }
  }

  ul {
    background: #eee;
    border-radius: 0 15px 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.8rem;

    @media(max-width: 575px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      width: calc(45% / 4);

      @media(max-width: 575px) {
        width: initial;
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 2rem;
    text-transform: uppercase;
    gap: 5px;
    
    svg {
      font-size: 2.5rem;
    }
  }

}