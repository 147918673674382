.postCard {
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  border-radius: 0 15px 0 15px;
  overflow: hidden;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;

  figure {
    position: relative;
    z-index: 0;
    max-width: 360px;
    width: 100%;
    max-height: 202.5px;
    line-height: 0;
    border-bottom-left-radius: 15px;
    overflow: hidden;

    img {
      max-width: 360px;
      width: 100%;
      height: auto;
      min-height: 203px;
      object-fit: cover;
      aspect-ratio: 16 / 9;
    }

    .postCategories {
      position: absolute;
      z-index: 5;
      top: 5px;
      left: 5px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .postTag {
      background: var(--default-purple);
      color: #fff;
      font-size: 1.2rem;
      line-height: initial;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-radius: 0 5px 0 5px;
      padding: 5px 10px;
      font-family: var(--headingFont);
      box-shadow: inset 0 0 0 1px var(--default-purple);

      &:hover {
        color: var(--default-purple);
        background: #fff;
      }
    }
  }

  .content {
    padding: 15px;

    @media (max-width: 991px) {
      width: 100%;
    }

    a {
      display: block;
    }

    time {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.2rem;
    }

    h2 {
      font-size: 2rem;
      margin: 5px 0 10px;
      max-height: 7.2rem;
      overflow: hidden;
    }

    p {
      font-size: 1.6rem;
      height: 8.8rem;
      overflow: hidden;
      line-height: 2.2rem;
    }
  }
}
