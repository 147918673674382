.postsWrapper {
  + .postsWrapper {
    margin-top: 30px;
  }

  .postsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 15px;
      max-width: 735px;
      width: 100%;
      margin: auto;
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  > h2 {
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;

    span {
      display: inline-block;
      border-bottom: 4px solid var(--default-purple);
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  @media (max-width: 767) {
    text-align: center;
  }
}
