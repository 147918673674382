.mainTitle {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  
  span {
    display: inline-block;
    border-bottom: 4px solid var(--default-purple);  
  }

  @media(max-width: 767px) {
    text-align: center;
  }
}